.newsArea {
    font-family: 'KoHo', sans-serif, 'Noto Sans JP', sans-serif;
    background-color: #F6F6F6;
    padding: 1rem 10vw 5rem 10vw;
    position: relative;
}

.navDiv {
    position: absolute;
    top: -72px;
    right: 5vw;
    font-size: 2rem;
}

.newsContainer {
    display: flex;
    justify-content: center;
}

.newsArticleWrapper {
    all: unset;
    cursor: pointer;
}

.newsArticleDivWrapper {
    all: unset;
}

.newsArticleContainer {
    display: inline-flex;
    flex-direction: column;
    min-width: 0;
}

.newsArticle {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    min-width: 0;
    margin-top: 20px;
}

.articleUnderline  {
    border-bottom: solid 1px #DCDCDC;
}

.newsArticleTitle {
    font-size: 1.1rem;
    margin: 0;
}

.newsArticleSubTitle {
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    margin-top: 5px;
    font-style: italic;
}

.newsArticleText {
    display: none;
    white-space: pre-wrap;
    font-size: 0.9rem;
}

.newsArticleTextSP {
    white-space: pre-wrap;
}

.newsArticleDate {
    display: none;
    white-space: pre-wrap;
    font-size: 0.9rem;
}

.newsArticleDateSP {
    white-space: pre-wrap;
    font-style: italic;
}

.newsArticleImage {
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 10px;
}

.newsTitle {
    font-weight: 400;
    text-align: center;
    font-size: 2.8rem;
    padding: 1rem;
}

.icon {
    margin-left: 1rem;
    color: white;
}

@media screen and (min-width: 600px) {
    .newsArticleText {
        display: block;
    }
    .newsArticleTextSP {
        display: none;
    }
    .newsArticleDate {
        display: block;
        font-style: italic;
    }
    .newsArticleDateSP {
        display: none;
    }
    .newsArticleImage {
        height: 150px;
        width: 150px;
        margin-bottom: 20px;
    }
    .newsArticleTitle {
        font-size: 1.3rem;
    }
    
    .newsArticleSubTitle {
        font-size: 1rem;
    }

    .newsArea {
        padding: 1rem 15vw 5rem 15vw;
    }
}

@media screen and (max-width: 400px) {
    .icon {
        display: none;
    }
}


