@import url(https://fonts.googleapis.com/css2?family=KoHo:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap);
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'KoHo', sans-serif, 'Noto Sans JP', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.instagram-feed .crt-stack .crt-stack-feed-container{
  padding:40px 20px 20px;
}

@media screen and (max-width: 768px){
  .instagram-feed .crt-stack .crt-stack-feed-container{
    flex-wrap: wrap;
    width:100%;
  }
  .instagram-feed .crt-stack .crt-stacked-cards-box{
    flex:auto;
    width:100%;
  }
  .instagram-feed .crt-stack .crt-stack-content{
    flex:auto;
    max-height: 250px!important;
    height:250px;
  }
  .instagram-feed .crt-stack .crt-stack-post-content .crt-stack-post-body .crt-stack-post-text-footer .crt-social-icon{
    left:0;
  }
  .instagram-feed .crt-stack .crt-stack-post-content .crt-stack-post-body .crt-stack-post-text-footer .crt-post-username{
    left: 25px;
    bottom:21px;
  }
  .instagram-feed .crt-stack .crt-stack-post-content .crt-stack-post-body .crt-stack-post-text-footer .crt-post-share{
    right:0px;
    bottom:25px;
  }

  .instagram-feed .crt-stack .crt-stacked-cards-box .hidden-card{
    width:100%;
    margin-left: auto;
  }
}
.AboutUs_aboutUsSection__1k9Va{
    min-height: 100vh;
    background: url(/static/media/AboutUsBG.41b7fdda.jpeg) no-repeat center center;
    background-size: cover;
    padding-bottom: 2rem;
}

.AboutUs_flexContainer__1suUn {
    display: flex;
    align-items: center;
    min-height: 70vh;
}

.AboutUs_textArea__1pDA5 {
    text-align: center;
}

.AboutUs_aboutUsTitle__1n8ZT {
    color: white;
    font-size: 2.8rem;
    font-weight: 400;
    padding: 1rem 15vw;
}

.AboutUs_aboutUsTextJp__Igs_R {
    color: white;
    margin: 0 15vw;
    font-weight: 500;
    font-size: 1rem;
}

.AboutUs_aboutUsTextEn__3F11m {
    color: white;
    margin: 0 15vw;
    font-weight: 500;
    font-size: 1.1rem;
}

.AboutUs_navbar__BkcPj {
    position: fixed;
    -webkit-transform: translateX(-100vw);
            transform: translateX(-100vw);
}

.AboutUs_navbarOpen__2Hlk7 {
    padding-left: 5vw;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    min-height: 100vh;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation: AboutUs_open-menu__NryGJ 1s;
            animation: AboutUs_open-menu__NryGJ 1s;
    opacity: 1;
    z-index: 100;
}

@-webkit-keyframes AboutUs_open-menu__NryGJ {
    0% {
        -webkit-transform: translateX(-100vw);
                transform: translateX(-100vw);
    }

    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@keyframes AboutUs_open-menu__NryGJ {
    0% {
        -webkit-transform: translateX(-100vw);
                transform: translateX(-100vw);
    }

    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

.AboutUs_leftSide__3Rokd {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
}

.AboutUs_rightSide__I9CHt {
    display: flex;
    flex-direction: column;
}

.AboutUs_logo__XvTqL {
   display: none;
}

.AboutUs_navLink__2XPob {
    text-decoration: none;
    color: black;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
}

.AboutUs_navLink__2XPob:hover {
    text-decoration: underline;
}

.AboutUs_langButtons__G6l7X {
    display: flex;
}

.AboutUs_socialMediaLinks__W6gur {
    display: flex;
    margin-top: 2rem;
    align-items: center;
}

.AboutUs_instagramIcon__1hLU6 {
    font-size: 1.5rem;
}

.AboutUs_facebookIcon__1LpUu {
    font-size: 1.5rem;
}

.AboutUs_closeButton__3LGog{
    padding: 1rem;
    margin: 0;
    font-size: 1.5rem;
}

.AboutUs_mobileNavbar__2KbIB {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: transparent;
    padding: 0.5rem 0 0.5rem 0;
}

.AboutUs_logoMobile__10WWC {
    height: 2.5rem;
    width: auto;
    margin: 0;
    padding: 0.5rem 3rem 0.5rem 0;
}

.AboutUs_hamburgerButton__3FlqC {
    font-size: 1.5rem;
    margin: 1rem 0 0 1rem;
    color: white;
}

.AboutUs_bolder__3DuQ_ {
    font-weight: bolder;
}

.AboutUs_bolder__3DuQ_:hover {
    cursor: auto;
    text-decoration: none;
}

.AboutUs_clickable__1Zwym:hover {
    cursor: pointer;
    text-decoration: underline;
}

.AboutUs_wifiOutlets__205U_{
    position: absolute;
}

@media (min-width: 850px) {

    .AboutUs_navbar__BkcPj{
        position: static;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 5vw 0.5rem 5vw;
        margin: 0;
        min-height: auto;
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }

    .AboutUs_navbarOpen__2Hlk7{
        position: static;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 5vw 0.5rem 5vw;
        margin: 0;
        min-height: auto;
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
    
    .AboutUs_leftSide__3Rokd {
        margin: 0;
        flex-direction: row;
        align-items: center;
    }
    
    .AboutUs_rightSide__I9CHt {
        flex-direction: row;
        align-items: center;
    }
    
    .AboutUs_logo__XvTqL {
        display: block;
        height: 2.5rem;
        width: auto;
        margin: 0rem 1rem 0rem 0;
    }
    
    .AboutUs_navLink__2XPob {
        padding: 0 1rem 0 1rem;
        color: white;
        cursor: pointer;
    }
  
    .AboutUs_socialMediaLinks__W6gur {
        display: flex;
        margin-top: 0.5rem;
        align-items: center;
    }

    .AboutUs_langButtons__G6l7X {
        display: flex;
        margin: 0;
        align-items: center;
    }

    .AboutUs_closeButton__3LGog{
        display: none;
    }

    .AboutUs_mobileNavbar__2KbIB {
        display: none;
    }

    .AboutUs_bolder__3DuQ_ {
        font-weight: bolder;
    }
    
    .AboutUs_bolder__3DuQ_:hover {
        cursor: auto;
        text-decoration: none;
    }
}
.Access_accessArea__DNOIZ {
    padding: 2rem 13vw 1rem 13vw;
    background-color: #F6F6F6;
    position: relative;
}

@media screen and (max-width: 450px) {
    .Access_accessArea__DNOIZ {
        padding: 2rem 10vw 1rem 10vw;
        background-color: #F6F6F6;
        position: relative;
    }
}

.Access_navDiv__3cY-_ {
    position: absolute;
    top: -70px;
}

.Access_accessTitle__1qkAV {
    font-weight: 400;
    text-align: center;
    font-size: 2.8rem;
}

.Access_flexContainerJp__32kiq {
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 10vw;
    -webkit-column-gap: 10vw;
            column-gap: 10vw;
    padding-bottom: 2rem;
    font-size: 0.95rem;
}

.Access_flexContainerEn__2qMTi {
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 10vw;
    -webkit-column-gap: 10vw;
            column-gap: 10vw;
    padding-bottom: 2rem;
}

.Access_locationArea__QkNlm {
    flex: 1 1;
    flex-shrink: 0;
    margin-right: 0.5rem;
    padding-bottom: 0;
}

.Access_map__wCKEG {
    min-width: 100%;
    height: 20rem;
}

.Access_openingTimesFlexContainer__3GEzV {
    display: flex;
    justify-content: space-between;
    width: 15rem;
}

.Access_locationDetails__nqQkR {
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center;
}

.Access_icon__2babX {
    font-size: 1.5rem;
}

@media (max-width: 400px) {
    .Access_map__wCKEG {
        width: 15rem;
    }
}
.Carousel_carouselContainer__uKZEH {
    height: 90vh;
    position: relative;
    background-color: #1B2D2B;
}

.Carousel_image1__a086v {
    height: 90vh;
    background: url(/static/media/IMG_7619.e18868f3.jpg) no-repeat center center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-between;
    -webkit-animation: Carousel_fade-in__hfO8G 1s;
            animation: Carousel_fade-in__hfO8G 1s;
}

.Carousel_image2__3D6sW {
    height: 90vh;
    background: url(/static/media/IMG_7629.26f933b6.jpg) no-repeat center center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-between;
    -webkit-animation: Carousel_fade-in__hfO8G 1s ease-in-out;
            animation: Carousel_fade-in__hfO8G 1s ease-in-out;
}

.Carousel_image3__3tMqI {
    height: 90vh;
    background: url(/static/media/IMG_8435.6e4b223a.jpg) no-repeat center center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-between;
    -webkit-animation: Carousel_fade-in__hfO8G 1s;
            animation: Carousel_fade-in__hfO8G 1s;
}

.Carousel_image4__2hIL5 {
    height: 90vh;
    background: url(/static/media/IMG_7556.d03595a6.jpg) no-repeat center center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-between;
    -webkit-animation: Carousel_fade-in__hfO8G 1s;
            animation: Carousel_fade-in__hfO8G 1s;
}

@-webkit-keyframes Carousel_fade-in__hfO8G {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.9);
                transform: scale(0.9)
    }

    100% {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1)
    }
}

@keyframes Carousel_fade-in__hfO8G {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.9);
                transform: scale(0.9)
    }

    100% {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1)
    }
}

.Carousel_arrowLeft__3ub68 {
    position: absolute;
    font-size: 2rem;
    top: 50%;
    padding: 1rem;
    color: white;
    background-color: teal;
    cursor: pointer;
    opacity: 0.9;
    -webkit-animation: Carousel_reverse-animation__qHB7d 0.3s;
            animation: Carousel_reverse-animation__qHB7d 0.3s;
}

.Carousel_arrowRight__4rWdx {
    position: absolute;
    font-size: 2rem;
    top: 50%;
    right: 0%;
    padding: 1rem;
    color: white;
    background-color: teal;
    cursor: pointer;
    opacity: 0.9;
    -webkit-animation: Carousel_reverse-animation__qHB7d 0.3s;
            animation: Carousel_reverse-animation__qHB7d 0.3s;
}

.Carousel_arrowLeft__3ub68:hover{
    -webkit-animation: Carousel_button-animation__2TGS2 0.3s forwards;
            animation: Carousel_button-animation__2TGS2 0.3s forwards;
}

.Carousel_arrowRight__4rWdx:hover{
    -webkit-animation: Carousel_button-animation__2TGS2 0.3s forwards;
            animation: Carousel_button-animation__2TGS2 0.3s forwards;
}

@-webkit-keyframes Carousel_button-animation__2TGS2 {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    
    100% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
}

@keyframes Carousel_button-animation__2TGS2 {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    
    100% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
}

@-webkit-keyframes Carousel_reverse-animation__qHB7d {
    0% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes Carousel_reverse-animation__qHB7d {
    0% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

.Carousel_imageLoad__1184q {
    display: none;
}
.Footer_footerArea__3-Yh9 {
    background-color: #DCDCDC;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 13vw;
    flex-wrap: wrap;
}

.Footer_facebookIcon__P6j3M {
    font-size: 1.5rem;
    color: black;
}

.Footer_instagramIcon__1mgTL {
    font-size: 1.5rem;
    color: black;
    padding-left: 1rem;
}
.Header_headerPreload__39H00 {
    opacity: 0;
}

.Header_header__1CGKd {
    opacity: 1;
    -webkit-animation: Header_fadein__2sFlx 0.2s;
            animation: Header_fadein__2sFlx 0.2s;
}

.Header_headerNone__21KCS {
    opacity: 0;
    -webkit-animation: Header_fadeout__3krDX 0.2s;
            animation: Header_fadeout__3krDX 0.2s;
}

@-webkit-keyframes Header_fadein__2sFlx {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes Header_fadein__2sFlx {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes Header_fadeout__3krDX {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes Header_fadeout__3krDX {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.Header_navbarPreload__2dCVx {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    -webkit-transform: translateX(-100vw);
            transform: translateX(-100vw);
    opacity: 0;
    z-index: 100;
}

.Header_navbar__2a0AU {
    position: fixed;
    -webkit-transform: translateX(-100vw);
            transform: translateX(-100vw);
    z-index: 100;
}

.Header_navbarOpen__r0vWt {
    padding-left: 5vw;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    min-height: 100vh;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation: Header_open-menu__1stoY 1s;
            animation: Header_open-menu__1stoY 1s;
    z-index: 100;
}

@-webkit-keyframes Header_open-menu__1stoY {
    0% {
        -webkit-transform: translateX(-100vw);
                transform: translateX(-100vw);
    }

    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@keyframes Header_open-menu__1stoY {
    0% {
        -webkit-transform: translateX(-100vw);
                transform: translateX(-100vw);
    }

    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

.Header_leftSide__2Xx_X {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
}

.Header_rightSide__39kk0 {
    display: flex;
    flex-direction: column;
}

.Header_logo__ffv_d {
   display: none;
}

.Header_navLink__3Orsl {
    text-decoration: none;
    color: black;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
}

.Header_navLink__3Orsl:hover {
    text-decoration: underline;
}

.Header_langButtons__1W2jS {
    display: flex;
}

.Header_socialMediaLinks__2eOLw {
    display: flex;
    margin-top: 2rem;
    align-items: center;
}

.Header_instagramIcon__17ygr {
    font-size: 1.5rem;
}

.Header_facebookIcon__2MmiY {
    font-size: 1.5rem;
}

.Header_closeButton__WItK7{
    padding: 1rem;
    margin: 0;
    font-size: 1.5rem;
}

.Header_mobileNavbar__QySrj {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: white;
    padding: 0.5rem 0 0.5rem 0;
    z-index: 100;
}

.Header_logoMobile__31jTQ {
    height: 2.5rem;
    width: auto;
    margin: 0;
    padding: 0.5rem 3rem 0.5rem 0;
}

.Header_hamburgerButton__2BzDl {
    font-size: 1.5rem;
    margin: 1rem 0 0 1rem;
}

.Header_bolder__1v4Aw {
    font-weight: bolder;
}

.Header_bolder__1v4Aw:hover {
    cursor: auto;
    text-decoration: none;
}

@media (min-width: 850px) {

    .Header_navbar__2a0AU{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background-color: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 5vw 0.5rem 5vw;
        margin: 0;
        min-height: auto;
        -webkit-transform: translateX(0);
                transform: translateX(0);
        z-index: 100;
    }

    .Header_navbarOpen__r0vWt{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background-color: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 5vw 0.5rem 5vw;
        margin: 0;
        min-height: auto;
        -webkit-transform: translateX(0);
                transform: translateX(0);
        z-index: 100;
    }
    
    .Header_leftSide__2Xx_X {
        margin: 0;
        flex-direction: row;
        align-items: center;
    }
    
    .Header_rightSide__39kk0 {
        flex-direction: row;
        align-items: center;
    }
    
    .Header_logo__ffv_d {
        display: block;
        height: 2.5rem;
        width: auto;
        margin: 0rem 1rem 0rem 0;
    }
    
    .Header_navLink__3Orsl {
        padding: 0 1rem 0 1rem;
        cursor: pointer;
    }

    .Header_navLink__3Orsl:hover {
        text-decoration: underline;
    }

    .Header_socialMediaLinks__2eOLw {
        display: flex;
        margin-top: 0.5rem;
        align-items: center;
    }

    .Header_langButtons__1W2jS {
        display: flex;
        margin: 0;
        align-items: center;
    }

    .Header_closeButton__WItK7{
        display: none;
    }

    .Header_mobileNavbar__QySrj {
        display: none;
    }

    .Header_bolder__1v4Aw {
        font-weight: bolder;
    }
    
    .Header_bolder__1v4Aw:hover {
        cursor: auto;
        text-decoration: none;
    }
}
.News_newsArea__3iAg2 {
    font-family: 'KoHo', sans-serif, 'Noto Sans JP', sans-serif;
    background-color: #F6F6F6;
    padding: 1rem 10vw 5rem 10vw;
    position: relative;
}

.News_navDiv__hUU0s {
    position: absolute;
    top: -72px;
    right: 5vw;
    font-size: 2rem;
}

.News_newsContainer__19VoQ {
    display: flex;
    justify-content: center;
}

.News_newsArticleWrapper__1k-Hm {
    all: unset;
    cursor: pointer;
}

.News_newsArticleDivWrapper__3iIyE {
    all: unset;
}

.News_newsArticleContainer__2RcLZ {
    display: inline-flex;
    flex-direction: column;
    min-width: 0;
}

.News_newsArticle__38A5l {
    display: flex;
    justify-content: flex-start;
    grid-gap: 20px;
    gap: 20px;
    min-width: 0;
    margin-top: 20px;
}

.News_articleUnderline__1i9XM  {
    border-bottom: solid 1px #DCDCDC;
}

.News_newsArticleTitle__IEgQH {
    font-size: 1.1rem;
    margin: 0;
}

.News_newsArticleSubTitle__qIQO5 {
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    margin-top: 5px;
    font-style: italic;
}

.News_newsArticleText__2RmAQ {
    display: none;
    white-space: pre-wrap;
    font-size: 0.9rem;
}

.News_newsArticleTextSP__2p8jn {
    white-space: pre-wrap;
}

.News_newsArticleDate__1oSTy {
    display: none;
    white-space: pre-wrap;
    font-size: 0.9rem;
}

.News_newsArticleDateSP__1sMNe {
    white-space: pre-wrap;
    font-style: italic;
}

.News_newsArticleImage__3y4d9 {
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 10px;
}

.News_newsTitle__1bMLy {
    font-weight: 400;
    text-align: center;
    font-size: 2.8rem;
    padding: 1rem;
}

.News_icon__286x6 {
    margin-left: 1rem;
    color: white;
}

@media screen and (min-width: 600px) {
    .News_newsArticleText__2RmAQ {
        display: block;
    }
    .News_newsArticleTextSP__2p8jn {
        display: none;
    }
    .News_newsArticleDate__1oSTy {
        display: block;
        font-style: italic;
    }
    .News_newsArticleDateSP__1sMNe {
        display: none;
    }
    .News_newsArticleImage__3y4d9 {
        height: 150px;
        width: 150px;
        margin-bottom: 20px;
    }
    .News_newsArticleTitle__IEgQH {
        font-size: 1.3rem;
    }
    
    .News_newsArticleSubTitle__qIQO5 {
        font-size: 1rem;
    }

    .News_newsArea__3iAg2 {
        padding: 1rem 15vw 5rem 15vw;
    }
}

@media screen and (max-width: 400px) {
    .News_icon__286x6 {
        display: none;
    }
}



.Menu_menuArea__1Dxx7 {
    background-color: #F6F6F6;
    white-space: pre-wrap;
}

.Menu_flexContainer__2V-NL {
    display: flex;
    justify-content: center;
    padding: 2rem;
}

.Menu_buttonContainer__1UgdK {
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-animation: Menu_zoom-out__3SCa1 0.3s forwards;
            animation: Menu_zoom-out__3SCa1 0.3s forwards;
}

@-webkit-keyframes Menu_zoom-out__3SCa1 {
    0% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }

    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes Menu_zoom-out__3SCa1 {
    0% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }

    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

.Menu_buttonContainer__1UgdK:hover {
    cursor: pointer;
    -webkit-animation: Menu_zoom-in__15o63 0.3s forwards;
            animation: Menu_zoom-in__15o63 0.3s forwards;
}

@-webkit-keyframes Menu_zoom-in__15o63 {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }

    100% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
}

@keyframes Menu_zoom-in__15o63 {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }

    100% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
}

.Menu_openMenuText__z92eL {
    margin: 0;
}

.Menu_arrow__1awM8 {
    font-size: 2rem;
}
.DrinksMenu_drinksMenuArea__2j-NW {
    padding: 2rem 10vw;
}

@media screen and (max-width: 450px) {
    .DrinksMenu_drinksMenuArea__2j-NW {
        padding: 2rem 3vw;
    }
}

.DrinksMenu_drinksMenuTitle__7xCz- {
    margin: 2rem 2rem 0 2rem;
    font-size: 2.8rem;
    font-weight: 400;
}

.DrinksMenu_flexContainer__3nMVy {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    grid-column-gap: 3rem;
    -webkit-column-gap: 3rem;
            column-gap: 3rem;
    margin-bottom: 3rem;
}

.DrinksMenu_menuLeft__lAiOR {
    flex: 1 1;
    min-width: 18rem;
}

.DrinksMenu_menuRight__3_YuS {
    flex: 1 1;
    padding-top: 0;
    margin-top: 0;
    min-width: 18rem;
}

.DrinksMenu_imagesArea__LArR8 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.DrinksMenu_singleImage__ajHTl {
    height: 12rem;
    flex: 1 1;
    padding: 2rem;
    margin-bottom: 2rem;
}

.DrinksMenu_menuImage__3Y9PW {
    max-height: 100%;
    min-width: 100%;
    object-fit: cover;
    vertical-align: bottom;
}

.DrinksMenu_caption__2SyXI {
    margin-bottom: 1rem;
}
.SingleItem_singleItem__1ofVP {
    display: flex;
    margin: 2rem 2rem 0 2rem;
}

.SingleItem_infoArea__UKLk5 {
    width: 100%;
}

.SingleItem_itemTitle__1--Pe {
    margin: 0 0.5rem 0.3rem 0;
    padding: 0;
    font-size: 1.1rem;
    font-weight: bolder;
}

.SingleItem_itemPrice__3g_yG {
    margin: 0;
    padding: 0;
    font-size: 1.1rem;
}

.SingleItem_extraInfo1Jp__3AiG- {
    margin: 0;
    padding: 0 0.2rem;
    border: black 1px solid;
    display:inline-block;
    font-size: 0.8rem;
}

.SingleItem_extraInfo2Jp__Tz8tr {
    margin: 0;
    padding: 1rem 1rem 0 1rem;
    font-style: italic;
    font-size: 0.8rem;
}

.SingleItem_extraInfo1En__1aQJB {
    margin: 0;
    padding: 0 0.2rem;
    border: black 1px solid;
    display:inline-block;
    font-size: 1rem;
}

.SingleItem_extraInfo2En__1NZGX {
    margin: 0;
    padding: 1rem 1rem 0 1rem;
    font-style: italic;
    font-size: 1rem;
}
.FoodMenu_foodMenuArea__27y39 {
    padding: 2rem 10vw;
    -webkit-animation: FoodMenu_open-menu__1uZ9m 1s;
            animation: FoodMenu_open-menu__1uZ9m 1s;
}


@media screen and (max-width: 450px) {
    .FoodMenu_foodMenuArea__27y39 {
        padding: 2rem 3vw;
        -webkit-animation: FoodMenu_open-menu__1uZ9m 1s;
                animation: FoodMenu_open-menu__1uZ9m 1s;
    }
}

@-webkit-keyframes FoodMenu_open-menu__1uZ9m {
    0% {
        max-height: 0;
    }

    100% {
        max-height: 1000px;
    }
}

@keyframes FoodMenu_open-menu__1uZ9m {
    0% {
        max-height: 0;
    }

    100% {
        max-height: 1000px;
    }
}

.FoodMenu_foodMenuTitle__1fiFz {
    margin: 2rem 2rem 0 2rem;
    font-size: 2.8rem;
    font-weight: 400;
}

.FoodMenu_flexContainer__1oO2D {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    grid-column-gap: 3rem;
    -webkit-column-gap: 3rem;
            column-gap: 3rem;
    margin-bottom: 3rem;
}

.FoodMenu_menuLeft__2ptDR {
    flex: 1 1;
    min-width: 18rem;
}

.FoodMenu_menuRight__gAzw4 {
    flex: 1 1;
    padding-top: 0;
    margin-top: 0;
    min-width: 18rem;
}

.FoodMenu_imagesArea__1Rl-w {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.FoodMenu_singleImage__JkZRs {
    height: 12rem;
    flex: 1 1;
    padding: 2rem;
    margin-bottom: 2rem;
}

.FoodMenu_menuImage__EiIec {
    max-height: 100%;
    min-width: 100%;
    object-fit: cover;
    vertical-align: bottom;
}

.FoodMenu_emptyImage__uh_mY {
    display: none;
}

@media screen and (min-width: 1024px) {
    .FoodMenu_emptyImage__uh_mY {
        display: block;
        height: 12rem;
        flex: 1 1;
        padding: 2rem;
        margin-bottom: 2rem;
    }
}
.ClosedDummyMenu_dummyMenu__1T8Cy {
    -webkit-animation: ClosedDummyMenu_close-menu__1F3J4 1s;
            animation: ClosedDummyMenu_close-menu__1F3J4 1s;
}

@-webkit-keyframes ClosedDummyMenu_close-menu__1F3J4 {
    0% {
        height: 400px;
    }

    100% {
        height: 0;
    }
}

@keyframes ClosedDummyMenu_close-menu__1F3J4 {
    0% {
        height: 400px;
    }

    100% {
        height: 0;
    }
}
.MenuTitle_menuTitleArea__2Bwsk {
    height: 100vh;
    background: url(/static/media/MenuTitleBG.32f63589.jpeg) no-repeat center center;
    background-size: cover;
    position: relative;
    z-index: -1;
}

.MenuTitle_navDiv__SjFIZ {
    position: absolute;
    top: -70px;
}

.MenuTitle_flexContainer__1tcTF {
    display: flex;
    height: 80vh;
    align-items: center;
}

.MenuTitle_textArea__ELXkP {
    text-align: center;
}

.MenuTitle_menuTitle__3p4ee {
    color: white;
    font-size: 2.8rem;
    font-weight: 400;
}

.MenuTitle_menuTitleTextJp__1SGyL {
    color: white;
    margin: 0 15vw;
    font-weight: 500;
    font-size: 1rem;
}

.MenuTitle_menuTitleTextEn__3COk9 {
    color: white;
    margin: 0 15vw;
    font-weight: 500;
    font-size: 1.1rem;
}
