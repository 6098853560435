.accessArea {
    padding: 2rem 13vw 1rem 13vw;
    background-color: #F6F6F6;
    position: relative;
}

@media screen and (max-width: 450px) {
    .accessArea {
        padding: 2rem 10vw 1rem 10vw;
        background-color: #F6F6F6;
        position: relative;
    }
}

.navDiv {
    position: absolute;
    top: -70px;
}

.accessTitle {
    font-weight: 400;
    text-align: center;
    font-size: 2.8rem;
}

.flexContainerJp {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10vw;
    padding-bottom: 2rem;
    font-size: 0.95rem;
}

.flexContainerEn {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10vw;
    padding-bottom: 2rem;
}

.locationArea {
    flex: 1;
    flex-shrink: 0;
    margin-right: 0.5rem;
    padding-bottom: 0;
}

.map {
    min-width: 100%;
    height: 20rem;
}

.openingTimesFlexContainer {
    display: flex;
    justify-content: space-between;
    width: 15rem;
}

.locationDetails {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.icon {
    font-size: 1.5rem;
}

@media (max-width: 400px) {
    .map {
        width: 15rem;
    }
}