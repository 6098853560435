.foodMenuArea {
    padding: 2rem 10vw;
    animation: open-menu 1s;
}


@media screen and (max-width: 450px) {
    .foodMenuArea {
        padding: 2rem 3vw;
        animation: open-menu 1s;
    }
}

@keyframes open-menu {
    0% {
        max-height: 0;
    }

    100% {
        max-height: 1000px;
    }
}

.foodMenuTitle {
    margin: 2rem 2rem 0 2rem;
    font-size: 2.8rem;
    font-weight: 400;
}

.flexContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 3rem;
    margin-bottom: 3rem;
}

.menuLeft {
    flex: 1;
    min-width: 18rem;
}

.menuRight {
    flex: 1;
    padding-top: 0;
    margin-top: 0;
    min-width: 18rem;
}

.imagesArea {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.singleImage {
    height: 12rem;
    flex: 1;
    padding: 2rem;
    margin-bottom: 2rem;
}

.menuImage {
    max-height: 100%;
    min-width: 100%;
    object-fit: cover;
    vertical-align: bottom;
}

.emptyImage {
    display: none;
}

@media screen and (min-width: 1024px) {
    .emptyImage {
        display: block;
        height: 12rem;
        flex: 1;
        padding: 2rem;
        margin-bottom: 2rem;
    }
}