@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');

.aboutUsSection{
    min-height: 100vh;
    background: url(../../Data/Images/AboutUs/AboutUsBG.jpeg) no-repeat center center; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-bottom: 2rem;
}

.flexContainer {
    display: flex;
    align-items: center;
    min-height: 70vh;
}

.textArea {
    text-align: center;
}

.aboutUsTitle {
    color: white;
    font-size: 2.8rem;
    font-weight: 400;
    padding: 1rem 15vw;
}

.aboutUsTextJp {
    color: white;
    margin: 0 15vw;
    font-weight: 500;
    font-size: 1rem;
}

.aboutUsTextEn {
    color: white;
    margin: 0 15vw;
    font-weight: 500;
    font-size: 1.1rem;
}

.navbar {
    position: fixed;
    transform: translateX(-100vw);
}

.navbarOpen {
    padding-left: 5vw;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    min-height: 100vh;
    transform: translateX(0);
    animation: open-menu 1s;
    opacity: 1;
    z-index: 100;
}

@keyframes open-menu {
    0% {
        transform: translateX(-100vw);
    }

    100% {
        transform: translateX(0);
    }
}

.leftSide {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
}

.rightSide {
    display: flex;
    flex-direction: column;
}

.logo {
   display: none;
}

.navLink {
    text-decoration: none;
    color: black;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
}

.navLink:hover {
    text-decoration: underline;
}

.langButtons {
    display: flex;
}

.socialMediaLinks {
    display: flex;
    margin-top: 2rem;
    align-items: center;
}

.instagramIcon {
    font-size: 1.5rem;
}

.facebookIcon {
    font-size: 1.5rem;
}

.closeButton{
    padding: 1rem;
    margin: 0;
    font-size: 1.5rem;
}

.mobileNavbar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: transparent;
    padding: 0.5rem 0 0.5rem 0;
}

.logoMobile {
    height: 2.5rem;
    width: auto;
    margin: 0;
    padding: 0.5rem 3rem 0.5rem 0;
}

.hamburgerButton {
    font-size: 1.5rem;
    margin: 1rem 0 0 1rem;
    color: white;
}

.bolder {
    font-weight: bolder;
}

.bolder:hover {
    cursor: auto;
    text-decoration: none;
}

.clickable:hover {
    cursor: pointer;
    text-decoration: underline;
}

.wifiOutlets{
    position: absolute;
}

@media (min-width: 850px) {

    .navbar{
        position: static;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 5vw 0.5rem 5vw;
        margin: 0;
        min-height: auto;
        transform: translateX(0);
    }

    .navbarOpen{
        position: static;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 5vw 0.5rem 5vw;
        margin: 0;
        min-height: auto;
        transform: translateX(0);
    }
    
    .leftSide {
        margin: 0;
        flex-direction: row;
        align-items: center;
    }
    
    .rightSide {
        flex-direction: row;
        align-items: center;
    }
    
    .logo {
        display: block;
        height: 2.5rem;
        width: auto;
        margin: 0rem 1rem 0rem 0;
    }
    
    .navLink {
        padding: 0 1rem 0 1rem;
        color: white;
        cursor: pointer;
    }
  
    .socialMediaLinks {
        display: flex;
        margin-top: 0.5rem;
        align-items: center;
    }

    .langButtons {
        display: flex;
        margin: 0;
        align-items: center;
    }

    .closeButton{
        display: none;
    }

    .mobileNavbar {
        display: none;
    }

    .bolder {
        font-weight: bolder;
    }
    
    .bolder:hover {
        cursor: auto;
        text-decoration: none;
    }
}