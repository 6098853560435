.drinksMenuArea {
    padding: 2rem 10vw;
}

@media screen and (max-width: 450px) {
    .drinksMenuArea {
        padding: 2rem 3vw;
    }
}

.drinksMenuTitle {
    margin: 2rem 2rem 0 2rem;
    font-size: 2.8rem;
    font-weight: 400;
}

.flexContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 3rem;
    margin-bottom: 3rem;
}

.menuLeft {
    flex: 1;
    min-width: 18rem;
}

.menuRight {
    flex: 1;
    padding-top: 0;
    margin-top: 0;
    min-width: 18rem;
}

.imagesArea {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.singleImage {
    height: 12rem;
    flex: 1;
    padding: 2rem;
    margin-bottom: 2rem;
}

.menuImage {
    max-height: 100%;
    min-width: 100%;
    object-fit: cover;
    vertical-align: bottom;
}

.caption {
    margin-bottom: 1rem;
}