.menuTitleArea {
    height: 100vh;
    background: url(../../Data/Images/MenuTitle/MenuTitleBG.jpeg) no-repeat center center; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: relative;
    z-index: -1;
}

.navDiv {
    position: absolute;
    top: -70px;
}

.flexContainer {
    display: flex;
    height: 80vh;
    align-items: center;
}

.textArea {
    text-align: center;
}

.menuTitle {
    color: white;
    font-size: 2.8rem;
    font-weight: 400;
}

.menuTitleTextJp {
    color: white;
    margin: 0 15vw;
    font-weight: 500;
    font-size: 1rem;
}

.menuTitleTextEn {
    color: white;
    margin: 0 15vw;
    font-weight: 500;
    font-size: 1.1rem;
}